  
import React from "react"
import styles from './index.module.scss';


const Layout = ({ data }) => {

  return (
    <div className={styles['main']}>
      <h1>Synopsys</h1>
      <h2>Who's Next?</h2>
    </div>
  )
}

export default Layout;
